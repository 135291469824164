<template>
  <div>
    <div class="nav">
      <div
        v-for="(item, index) in nav"
        :key="index"
        class="navItem"
        @click="navClick(index)"
      >
        <div class="navItem-name">{{ item.name }}</div>
        <div class="nav-underline" v-if="index == navIndex"></div>
      </div>
    </div>

    <div style="background:#fff;">
      <div class="receiving flex" v-for="(item, index) in [0, 1]" :key="index">
        <div class="">
          <div class="receiving-title">大转盘奖励名称</div>
          <div class="receiving-time">2022.08.26 22:00</div>
        </div>
        <div class="receiving-name">张三</div>
      </div>

      <div class="receiving flex" v-for="(item, index) in [0, 1]" :key="index">
        <div class="">
          <div class="receiving-title">大转盘奖励名称</div>
          <div class="receiving-time">2022.08.26 22:00</div>
        </div>
        <div class="flex">
          <div class="receiving-num">10</div>
          <div class="receiving-integral">积分</div>
        </div>
      </div>

      <div class="receiving flex" v-for="(item, index) in [0, 1]" :key="index">
        <div class="">
          <div class="receiving-title">优惠券名称优惠券名称优惠券名称</div>
          <div class="receiving-time">2022.08.26 22:00</div>
        </div>
        <div class="flex">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "提醒" }, { name: "签到" }, { name: "优惠券" }],
      navIndex: 0,
    };
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  padding: 0 111px 0 127px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  background: #fff;
  margin: 0 0 17px 0;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
}

.navItem-name {
  margin-top: 34px;
}
.nav-underline {
  width: 91px;
  height: 6px;
  background: #0558f4;
  border-radius: 3px 3px 3px 3px;
  bottom: 0;
  position: absolute;
}

.receiving {
  width: 670px;
  margin: 0 auto;
  border-bottom: 1px solid #ededf7;
  padding: 43px 0 37px 0;
  justify-content: space-between;
  background: #fff;
}

.receiving-name {
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.receiving-title {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.receiving-time {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin-top: 16px;
}

.receiving-num {
  font-size: 40px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #101f48;
}
.receiving-integral {
  margin-top: 18px;
}
</style>
